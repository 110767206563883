import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { PdLetDirectiveModule } from '@cdk/pd-let';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsAbsoluteExpansionPanelComponent } from '@design-system/components/absolute-expansion-panel';
import { DsFilterModule } from '@design-system/components/filter';
import { DsFilterInputModule } from '@design-system/components/filter-input';
import { DsLoadingModule } from '@design-system/components/loading';
import { DsPlaceholderModule } from '@design-system/components/placeholder';
import {
  DsTableLoadingModule,
  DsTableModule,
} from '@design-system/components/table';
import { DsTextIndicatorModule } from '@design-system/components/text-indicator';
import { DsPageModule } from '@design-system/feature/page';
import { DsSpotlightModule } from '@design-system/feature/spotlight';
import { CrmCustomerModule } from '@features/crm-customer';
import { SharedLibFeatSapCustomerModule } from '@features/sap-customer';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import {
  SlSharedProductLineIconComponent,
  SlSharedReportingBarChartComponent,
  SlSharedReportingSingleValueWidgetComponent,
} from '@sales-libs/shared/ui';
import {
  SalestoolSharedModule,
  SlSharedImageSizePipe,
} from '@sales-libs/shared/util';
import { GoogleAnalytics4DirectiveModule } from '@shared-lib/google-analytics';
import { LanguageSelectionModule } from '@shared-lib/language-selection';
import { EditorModule } from '@tinymce/tinymce-angular';
import { FileSizePipeModule } from '@utils/file-size-pipe';
import { SlProjectCalculationModule } from './calculation/calculation.module';
import {
  CalculationEffects,
  CalculationState,
  calculationReducer,
} from './calculation/store';
import { SlProjectCartCompareTableComponent } from './cart-compare/cart-compare-table/cart-compare-table.component';
import { SlProjectCartItemTypePipe } from './cart-compare/cart-compare-table/pipe/cart-item-type-pipe/cart-item-type.pipe';
import { SlProjectCartCompareDifferenceTypePipe } from './cart-compare/cart-compare-table/pipe/compare-difference-type-pipe/compare-difference-type.pipe';
import { SlProjectCartCompareRowComponent } from './cart-compare/cart-compare-table/rows/compare-row.component';
import { SlProjectCartCompareComponent } from './cart-compare/cart-compare.component';
import { SlProjectCartItemsModule } from './cart-items/cart-items.module';
import { SlProjectCartListComponent } from './cart-list/cart-list.component';
import { SlProjectCartValidDependendPageComponent } from './cart-valid-dependend-page/cart-valid-dependend-page.component';
import { SlProjectCheckoutDocumentsComponent } from './checkout-documents/checkout-documents.component';
import { SlProjectCheckoutWithoutOrderComponent } from './checkout-without-order/checkout-without-order.component';
import { SlProjectConfigurationOptionsTableComponent } from './configuration-options-table/configuration-options-table.component';
import { SlProjectConfigurationOptionsComponent } from './configuration-options/configuration-options.component';
import {
  DigitalOfferEffects,
  DigitalOfferState,
  digitalOfferReducer,
} from './digital-offer/store';
import { SlProjectDocumentListComponent } from './document-list/document-list.component';
import {
  DocumentsEffects,
  DocumentsState,
  documentsReducer,
} from './document-list/store';
import { SlProjectEditCustomCartItemComponent } from './edit-custom-cart-item/edit-custom-cart-item.component';
import { SlProjectEditLostOrderComponent } from './edit-lost-order/edit-lost-order.component';
import { SlProjectEditComponent } from './edit-project/edit-project.component';
import { SlProjectLostOrderModule } from './lost-order/lost-order.module';
import {
  LostOrderEffects,
  LostOrderState,
  lostOrderReducer,
} from './lost-order/store';
import { SlProjectMccDialogComponent } from './mcc-dialog/mcc-dialog.component';
import { MccEffects, MccState, mccReducer } from './mcc-dialog/store';
import {
  SlProjectReasonTranslationPipe,
  SlProjectStateTranslationKeyPipe,
} from './pipes';
import { SlProjectPriceDifferenceDialogComponent } from './price-difference-dialog/price-difference-dialog.component';
import { SlProjectItemDependendRemovalComponent } from './product-dependend-removal/product-dependend-removal.component';
import { SlProjectProductPreviewPricesComponent } from './product-preview/product-preview-prices/product-preview-prices.component';
import { SlProjectProductPreviewComponent } from './product-preview/product-preview.component';
import { SlProjectDataCreationDialogComponent } from './project-data-creation-dialog/project-data-creation-dialog.component';
import { SlProjectDetailsComponent } from './project-details/project-details.component';
import { SlProjectListSlProjectActionsColumnComponent } from './project-overview/project-list/project-list-actions-column/project-list-actions-column.component';
import { SlProjectListChanceColumnComponent } from './project-overview/project-list/project-list-chance-column/project-list-chance-column.component';
import { SlProjectListClosedDateColumnComponent } from './project-overview/project-list/project-list-closed-date-column/project-list-closed-date-column.component';
import { SlProjectListCodeColumnComponent } from './project-overview/project-list/project-list-code-column/project-list-code-column.component';
import { SlProjectListCreatedByColumnComponent } from './project-overview/project-list/project-list-created-by-column/project-list-created-by-column.component';
import { SlProjectListCreatedDateColumnComponent } from './project-overview/project-list/project-list-created-date-column/project-list-created-date-column.component';
import { SlProjectListCustomerColumnComponent } from './project-overview/project-list/project-list-customer-column/project-list-customer-column.component';
import { SlProjectListModelColumnComponent } from './project-overview/project-list/project-list-model-column/project-list-model-column.component';
import { SlProjectListNameColumnComponent } from './project-overview/project-list/project-list-name-column/project-list-name-column.component';
import { SlProjectListStateColumnComponent } from './project-overview/project-list/project-list-state-column/project-list-state-column.component';
import { SlProjectListComponent } from './project-overview/project-list/project-list.component';
import { SlProjectOverviewComponent } from './project-overview/project-overview.component';
import { SlProjectOverviewFiltersComponent } from './project-overview/projects-filters/projects-filters.component';
import { SlProjectLostOrderReportingFiltersComponent } from './reporting/lost-order-reporting-overview/lost-order-reporting-filters/lost-order-reporting-filters.component';
import { SlProjectLostOrderReportingOverviewComponent } from './reporting/lost-order-reporting-overview/lost-order-reporting-overview.component';
import { CartEffects, CartState, cartReducer } from './store/cart';
import { ContractItemEffects } from './store/cart/item-effects/contract-item.effects';
import { CustomArticleItemEffects } from './store/cart/item-effects/custom-article-item.effects';
import { MccItemEffects } from './store/cart/item-effects/mcc-item.effects';
import { ProductItemEffects } from './store/cart/item-effects/product-item.effects';
import { SalesOptionItemEffects } from './store/cart/item-effects/sales-options-item.effects';
import { SolutionItemEffects } from './store/cart/item-effects/solution-item.effects';
import { TuningCenterItemEffects } from './store/cart/item-effects/tuning-center-item.effects';
import { WarrantyExtensionItemEffects } from './store/cart/item-effects/warranty-extension-item.effects';
import {
  LostOrderListEffects,
  LostOrderListState,
  lostOrderListReducer,
} from './store/lost-order-list';
import { ProjectEffects, ProjectState, projectReducer } from './store/projects';
import { SlProjectSummaryDialogComponent } from './summary-dialog/summary-dialog.component';
import {
  TuningCenterState,
  tuningCenterReducer,
} from './tuning-center-dialog/store';
import { TuningCenterEffects } from './tuning-center-dialog/store/tuning-center.effects';
import { SlProjectTuningCenterDialogComponent } from './tuning-center-dialog/tuning-center-dialog.component';

export interface ProjectModuleState {
  documents: DocumentsState;
  calculation: CalculationState;
  lostOrder: LostOrderState;
  tuningCenter: TuningCenterState;
  mcc: MccState;
  cart: CartState;
  project: ProjectState;
  lostOrderList: LostOrderListState;
  digitalOffer: DigitalOfferState;
}

const ProjectOverallReducers: ActionReducerMap<ProjectModuleState, any> = {
  documents: documentsReducer,
  calculation: calculationReducer,
  lostOrder: lostOrderReducer,
  tuningCenter: tuningCenterReducer,
  mcc: mccReducer,
  cart: cartReducer,
  project: projectReducer,
  lostOrderList: lostOrderListReducer,
  digitalOffer: digitalOfferReducer,
};

@NgModule({
  imports: [
    CommonModule,
    SalestoolSharedModule,
    DsPlaceholderModule,
    MatProgressSpinnerModule,
    TranslateModule,
    RouterModule,
    SlProjectCartItemsModule,
    DsTextIndicatorModule,
    MatDialogModule,
    FlexLayoutModule,
    SalestoolSharedModule,
    DsSpacingModule,
    DsSpotlightModule,
    DsPageModule,
    DsAbsoluteExpansionPanelComponent,
    DsTableLoadingModule,
    FileSizePipeModule,
    StoreModule.forFeature('projectModule', ProjectOverallReducers, {}),
    EffectsModule.forFeature([
      CalculationEffects,
      LostOrderEffects,
      TuningCenterEffects,
      MccEffects,
      DocumentsEffects,
      CartEffects,
      ProjectEffects,
      LostOrderListEffects,
      // cart item effects
      ContractItemEffects,
      CustomArticleItemEffects,
      MccItemEffects,
      ProductItemEffects,
      SalesOptionItemEffects,
      TuningCenterItemEffects,
      WarrantyExtensionItemEffects,
      SolutionItemEffects,
      DigitalOfferEffects,
      MatProgressSpinnerModule,
    ]),
    DsTableModule,
    DsLoadingModule,
    CrmCustomerModule,
    SharedLibFeatSapCustomerModule,
    EditorModule,
    LanguageSelectionModule,
    DsFilterModule,
    DsFilterInputModule,
    SlProjectCartValidDependendPageComponent,
    SlProjectCalculationModule,
    GoogleAnalytics4DirectiveModule,
    PdLetDirectiveModule,
    SlSharedReportingBarChartComponent,
    SlSharedReportingSingleValueWidgetComponent,
    SlSharedProductLineIconComponent,
    DsFilterInputModule,
    SlSharedImageSizePipe,
  ],
  declarations: [
    SlProjectDetailsComponent,
    SlProjectProductPreviewComponent,
    SlProjectSummaryDialogComponent,
    SlProjectProductPreviewPricesComponent,
    SlProjectPriceDifferenceDialogComponent,
    SlProjectTuningCenterDialogComponent,
    SlProjectMccDialogComponent,
    SlProjectItemDependendRemovalComponent,
    SlProjectOverviewFiltersComponent,
    SlProjectListComponent,
    SlProjectOverviewComponent,
    SlProjectListCodeColumnComponent,
    SlProjectListStateColumnComponent,
    SlProjectListCreatedByColumnComponent,
    SlProjectListNameColumnComponent,
    SlProjectListModelColumnComponent,
    SlProjectListChanceColumnComponent,
    SlProjectListClosedDateColumnComponent,
    SlProjectListCreatedDateColumnComponent,
    SlProjectListCustomerColumnComponent,
    SlProjectListSlProjectActionsColumnComponent,
    SlProjectCartListComponent,
    SlProjectEditComponent,
    SlProjectDocumentListComponent,
    SlProjectStateTranslationKeyPipe,
    SlProjectReasonTranslationPipe,
    SlProjectEditLostOrderComponent,
    SlProjectEditCustomCartItemComponent,
    SlProjectConfigurationOptionsComponent,
    SlProjectConfigurationOptionsTableComponent,
    SlProjectCartCompareComponent,
    SlProjectCartCompareTableComponent,
    SlProjectCartCompareRowComponent,
    SlProjectCartItemTypePipe,
    SlProjectCartCompareDifferenceTypePipe,
    SlProjectDataCreationDialogComponent,
    SlProjectCheckoutWithoutOrderComponent,
    SlProjectCheckoutDocumentsComponent,
    SlProjectLostOrderReportingOverviewComponent,
    SlProjectLostOrderReportingFiltersComponent,
  ],
  exports: [
    SlProjectDetailsComponent,
    SlProjectCartValidDependendPageComponent,
    SlProjectProductPreviewComponent,
    SlProjectSummaryDialogComponent,
    SlProjectProductPreviewPricesComponent,
    SlProjectPriceDifferenceDialogComponent,
    SlProjectTuningCenterDialogComponent,
    SlProjectMccDialogComponent,
    SlProjectItemDependendRemovalComponent,
    SlProjectOverviewFiltersComponent,
    SlProjectListComponent,
    SlProjectOverviewComponent,
    SlProjectListCodeColumnComponent,
    SlProjectListStateColumnComponent,
    SlProjectListCreatedByColumnComponent,
    SlProjectListNameColumnComponent,
    SlProjectListModelColumnComponent,
    SlProjectListChanceColumnComponent,
    SlProjectListClosedDateColumnComponent,
    SlProjectListCreatedDateColumnComponent,
    SlProjectListCustomerColumnComponent,
    SlProjectListSlProjectActionsColumnComponent,
    SlProjectCartListComponent,
    SlProjectEditComponent,
    SlProjectDocumentListComponent,
    SlProjectCheckoutWithoutOrderComponent,
    SlProjectEditLostOrderComponent,
    SlProjectEditCustomCartItemComponent,
    SlProjectCalculationModule,
    SlProjectCartItemsModule,
    SlProjectLostOrderModule,
    SlProjectConfigurationOptionsComponent,
    SlProjectConfigurationOptionsTableComponent,
    SlProjectCartCompareComponent,
    SlProjectCartCompareTableComponent,
    SlProjectCartCompareRowComponent,
    SlProjectDataCreationDialogComponent,
    SlProjectCheckoutDocumentsComponent,
    SlProjectLostOrderReportingOverviewComponent,
    SlProjectLostOrderReportingFiltersComponent,
  ],
})
export class SlProjectModule {}
